<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('comm.feedback.filters') }}</span>
                <span style="float: right">
                    <el-button
                        class="b-c-apply-filters"
                        type="primary"
                        size="mini"
                        icon="el-icon-check"
                        plain
                        @click.native="applyFilters"
                        >{{ $t('general.apply') }}</el-button
                    >
                    <el-button
                        class="b-c-reset-filters"
                        type="warning"
                        size="mini"
                        icon="el-icon-refresh"
                        plain
                        @click.native="resetFilters"
                    >
                        {{ $t('general.reset') }}</el-button
                    >
                </span>
            </div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('comm.feedback.dateFrom') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        v-model="filters.minDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="$t('comm.feedback.dateFrom')"
                    >
                    </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('comm.feedback.dateTo') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        v-model="filters.maxDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="$t('comm.feedback.dateTo')"
                    >
                    </el-date-picker>
                </el-col>
            </el-row>
            <div class="separator"></div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('comm.feedback.name') }}</div>
                    <el-input v-model="filters.name" :placeholder="$t('comm.feedback.name')"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('comm.feedback.sites') }}</div>
                    <el-select v-model="filters.site" filterable class="fullWidth">
                        <el-option :label="$t('comm.feedback.allSites')" :value="0"></el-option>
                        <el-option v-for="item in sites" :label="item" :value="item" :key="item"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('comm.feedback.answer') }}</div>
                    <el-select v-model="filters.raspuns" filterable class="fullWidth">
                        <el-option :label="$t('comm.feedback.allAnswers')" :value="-1"></el-option>
                        <el-option :label="$t('general.yes')" :value="1"></el-option>
                        <el-option :label="$t('general.no')" :value="0"></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-card>

        <div class="separator" />

        <el-card>
            <el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage" :total="count"
                :page-size="limit" @current-change="changePage">
            </el-pagination>
            <div class="mini-separator"></div>
            <el-table :data="contacts" style="width: 100%" size="small" border>
                <el-table-column width="150px" prop="orders_id" :label="$t('comm.feedback.order-id')">
                </el-table-column>
                <el-table-column prop="data" :label="$t('comm.feedback.date')"> </el-table-column>
                <el-table-column prop="nume_client" :label="$t('comm.contactOrder.clientname')"> </el-table-column>
                <el-table-column prop="telefon_client" :label="$t('comm.contactOrder.clientphone')"> </el-table-column>
                <el-table-column prop="sursa_site" :label="$t('comm.contactOrder.site')"> </el-table-column>
                <el-table-column prop="mesaj" :label="$t('comm.contactOrder.message')"> </el-table-column>
                <el-table-column prop="hasAnswer" :label="$t('comm.contactOrder.hasAnswer')">
                    <template slot-scope="scope">
                        <div v-if="scope.row.hasAnswer == 1">{{ $t('general.yes') }}</div>
                        <div v-else>{{ $t('general.no') }}</div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('comm.contactOrder.toorder')">
                    <template slot-scope="scope">
                        <el-button
                            class="b-c-gotoorder"
                            plain
                            type="success"
                            size="small"
                            @click.native="gotoOrder(scope.row.orders_id)"
                        >
                            {{ $t('comm.contactOrder.gotoButton') + ' - ' + scope.row.orders_id }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div class="mini-separator"></div>
            <el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage" :total="count"
                :page-size="limit" @current-change="changePage">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import { getOrdersContacts, getUnansweredOrderContact } from '@/api/comunicare';

export default {
    data() {
        return {
            contacts: [],
            filters: {
                minDate: '',
                maxDate: '',
                name: '',
                site: 0,
                raspuns: -1,
                start: 0,
                withCount: true
            },
            sites: [],
            count: 0,
            currentPage: 1,
            limit: 5
        };
    },
    methods: {
        getOrdersContacts(keepSites) {
            getOrdersContacts(this.filters)
                .then((res) => {
                    this.contacts = res.message.contacts;
                    this.count = res.message.count;
                    if ('undefined' === typeof keepSites || keepSites === false) this.sites = res.message.sites;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getOrdersContacts',
                            params: this.filters
                        }
                    });
                });
        },
        gotoOrder(oid) {
            // this.$router.push({
            //     path: '/vanzari/comanda/' + oid
            // })
            window.open(this.$router.resolve('/vanzari/comanda/' + oid).href);
        },
        applyFilters() {
            this.getOrdersContacts(true);
            this.currentPage = 1;
        },
        resetFilters() {
            this.filters = {
                minDate: '',
                maxDate: '',
                name: '',
                site: 0,
                raspuns: -1,
                start: 0,
                withCount: true
            };
            this.currentPage = 1;
            this.getOrdersContacts();
        },
        changePage(page) {
            this.filters.start = page * this.limit - this.limit;
            this.currentPage = page;
            this.getOrdersContacts();
		}
    },
    computed: {},
    mounted() {
        this.getOrdersContacts();
    }
};
</script>
